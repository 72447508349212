import React, { useState, useEffect } from 'react'
import { Container, Box, Tab } from '@material-ui/core'
import { TabPanel, TabList, TabContext } from '@material-ui/lab'
import Numbers from './components/Numbers/Numbers.js'
import Weather from './components/Weather/Weather.js'
import InsertTool from './components/InsertTool/InsertTool.js'
import {
    getRecentNumber,
    getNumbers,
    getNumbersVideos,
    getWeatherVideos,
    getRecentNumbersVideo,
    getRecentWeatherVideo,
} from './api/davidnumbersapi'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

const App = () => {
    const [data, setData] = useState({})
    const [value, setValue] = useState('1')
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    //test data
    // const testNumbersVideos = [
    //     { yt_id: 'W-3MP27IU-I', date: '08/17/2020' },
    //     { yt_id: 'c9jFcIwEQ3k', date: '08/18/2020' },
    // ]

    // const testWeatherVideos = [
    //     { yt_id: 'W-3MP27IU-I', date: '08/17/2020' },
    //     { yt_id: 'c9jFcIwEQ3k', date: '08/18/2020' },
    // ]

    //Set Full Data On Load Once
    const setFullData = async () => {
        var testData = {}
        var numbers
        var recentNumber
        var numbersVideos
        var weatherVideos
        var recentNumbersVideoID
        var recentWeatherVideoID

        //Function for getting all numbers
        async function getAllNumbers() {
            const response = await getNumbers()
            numbers = response
            //Cached Data
            // var json = require('./api/DummyData/cachedNumbers.json')
            // numbers = json
        }
        await getAllNumbers()

        //Function for getting recent number
        async function getLatestNumber() {
            const response = await getRecentNumber()
            recentNumber = response[0].number
            //Test Data
            // recentNumber = '4'
        }
        await getLatestNumber()

        //Function for getting all numbers videos
        async function getAllNumbersVideos() {
            const response = await getNumbersVideos()
            // console.log('hehhh', response)
            numbersVideos = response[0]
            //Cached Data
            // numbersVideos = require('./api/DummyData/cachedNumbersVideos.json')
            // numbersVideos = testNumbersVideos;
        }
        await getAllNumbersVideos()

        //Function for getting latest numbers video
        async function getLatestNumbersVideo() {
            const response = await getRecentNumbersVideo()
            recentNumbersVideoID = response[0].yt_id
            //Test Data
            // recentNumbersVideoID = 'v3cNiB5fogE';
        }
        await getLatestNumbersVideo()

        //Function for getting latest weather video
        async function getLatestWeatherVideo() {
            const response = await getRecentWeatherVideo()
            recentWeatherVideoID = response[0].yt_id
            //Test Data
            // recentWeatherVideoID = 'aXztd30wk_U';
        }
        await getLatestWeatherVideo()

        //Function for getting all weather videos
        async function getAllWeatherVideos() {
            const response = await getWeatherVideos()
            weatherVideos = response[0]
            // var yt_id = response[0].yt_id;
            //Cached Data
            // weatherVideos = require('./api/DummyData/cachedWeatherVideos.json')
            // weatherVideos = testWeatherVideos;
        }
        await getAllWeatherVideos()
        testData.numbers = numbers
        testData.recentNumber = recentNumber
        testData.numbersVideos = numbersVideos
        testData.weatherVideos = weatherVideos
        testData.recentNumbersVideoID = recentNumbersVideoID
        testData.recentWeatherVideoID = recentWeatherVideoID
        setData(testData)
    }

    useEffect(() => {
        setFullData()
    }, [])

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/inserttool">
                    <InsertTool />
                </Route>
                <Route path="/">
                    <Container
                        maxWidth="xl"
                        style={{ backgroundColor: '#ffffff' }}
                    >
                        <Box
                            sx={{
                                height: '100vh',
                                textAlign: 'center',
                            }}
                        >
                            <TabContext value={value}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    }}
                                >
                                    <TabList
                                        onChange={handleChange}
                                        aria-label="lab API tabs example"
                                        centered
                                    >
                                        <Tab label="Numbers" value="1" />
                                        <Tab label="Weather" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Numbers data={data} />
                                </TabPanel>
                                <TabPanel value="2">
                                    <Weather data={data} />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Container>
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default App
