import React, { useState } from 'react'
import { Button, ButtonGroup, Grid } from '@material-ui/core'
import Statistics from './components/statistics/Statistics'
import NumbersVideos from './components/videos/NumbersVideos'
import ReactSpoiler from 'react-spoiler'
const Numbers = ({ data }) => {
    const [numbersView, setNumbersView] = useState('home')
    // useEffect(() => {
    // async function test() {
    //     const response = await getAllVideos();
    //     setVideos(JSON.parse(response));
    // }
    // test();
    // console.log('videos',videos);
    // async function getLatestVideo(){
    // const response = await getRecentVideo();
    // var yt_id = response[0].yt_id
    // setRecentNumbersVideoID(data.recentNumbersVideoID);
    // }
    // getLatestVideo();
    // }, [data.recentNumbersVideoID]);

    //test function to update excel sheet with youtube links
    //   useEffect(() => {
    //       async function test() {
    //           const response = await getYoutubeLinks();
    //           console.log(response);
    //       };
    //       test();
    //   })
    return (
        <div>
            <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                style={{ marginBottom: '10px' }}
            >
                <Button onClick={() => setNumbersView('home')}>Home</Button>
                <Button onClick={() => setNumbersView('statistics')}>
                    Statistics
                </Button>
                <Button onClick={() => setNumbersView('videos')}>
                    Historical Videos
                </Button>
            </ButtonGroup>
            {numbersView === 'home' ? (
                <div>
                    <div>
                        <h1>WHAT NUMBER DID DAVID LYNCH PICK TODAY?</h1>
                    </div>
                    <Grid
                        direction="row"
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <h3>NUMBER:</h3>
                        <ReactSpoiler
                            style={{
                                paddingLeft: '10px',
                                fontWeight: 'bold',
                                fontSize: '18px',
                            }}
                        >
                            {data.recentNumber}
                        </ReactSpoiler>
                    </Grid>
                    <Grid style={{ textAlign: '-webkit-center' }}>
                        <div style={{ width: '52vw', height: '52vh' }}>
                            <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${data.recentNumbersVideoID}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            />
                        </div>
                    </Grid>
                </div>
            ) : numbersView === 'statistics' ? (
                <Statistics numbers={data.numbers} />
            ) : (
                <NumbersVideos videoids={data.numbersVideos} />
            )}
        </div>
    )
}

export default Numbers
