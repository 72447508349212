import React, { useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Container, Button, ButtonGroup } from '@material-ui/core'
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    LineChart,
    Line,
    AreaChart,
    Area,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
} from 'recharts'

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'number',
        headerName: 'Number',
        width: 150,
        editable: true,
    },
    {
        field: 'formatted_date',
        headerName: 'Date',
        width: 150,
        editable: true,
    },
    {
        field: 'link',
        headerName: 'Link',
        width: 150,
        editable: true,
    },
]

//   const rows = [
//     { id: 1, number: '8', date: '8/17/2020', link: '' },
//     { id: 2, number: '3', date: '8/18/2020', link: '' },
//   ];

const Statistics = ({ numbers }) => {
    const [statisticView, setStatisticView] = useState('datagrid')
    return (
        <Container maxWidth="xl">
            <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                style={{ marginBottom: '10px' }}
            >
                <Button onClick={() => setStatisticView('datagrid')}>
                    Data Grid
                </Button>
                <Button onClick={() => setStatisticView('areachart')}>
                    Area Chart
                </Button>
                <Button onClick={() => setStatisticView('barchart')}>
                    Bar Chart
                </Button>
                <Button onClick={() => setStatisticView('linechart')}>
                    Line Chart
                </Button>
            </ButtonGroup>
            {statisticView === 'datagrid' ? (
                <DataGrid
                    rows={numbers}
                    columns={columns}
                    autoHeight
                    pageSize={5}
                    checkboxSelection
                    disableSelectionOnClick
                />
            ) : statisticView === 'areachart' ? (
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart
                        width={500}
                        height={400}
                        data={numbers}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="formatted_date" />
                        <YAxis />
                        <Legend />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="number"
                            stroke="#c41d3f"
                            fill="#c41d3f"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            ) : statisticView === 'barchart' ? (
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart width={800} height={300} data={numbers}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="formatted_date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="number" fill="#c41d3f" />
                        {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        data={numbers}
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                    >
                        <Line
                            type="monotone"
                            dataKey="number"
                            stroke="#c41d3f"
                        />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="formatted_date" />
                        <YAxis />
                        <Legend />
                        <Tooltip />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </Container>
    )
}

export default Statistics
