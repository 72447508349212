import axios from 'axios'
import youtube from './youtube.js'

const getYoutubeLinks = async () => {
    var consolidatedLinks = []
    await youtube
        .get('/search', {
            params: {
                q: 'Weather',
            },
        })
        .then((response) => {
            console.log(response.data.items)
            let items = response.data.items
            for (let i = 0; i < items.length; i++) {
                consolidatedLinks.push(items[i].id.videoId)
                // consolidatedLinks.push(items[i].snippet.publishedAt);
            }
            console.log(consolidatedLinks)
        })
}

const getNumbersVideos = async () => {
    var videos
    await axios
        .get(
            'https://9x9v95v7r4.execute-api.us-east-1.amazonaws.com/dev/getvideos?functiontype=2'
        )
        .then((response) => {
            videos = response.data
        })
    return [videos]
}

const getRecentNumbersVideo = async () => {
    var video
    await axios
        .get(
            'https://9x9v95v7r4.execute-api.us-east-1.amazonaws.com/dev/getvideos?functiontype=1'
        )
        .then((response) => {
            video = response.data
        })
    return video
}

const getNumbers = async () => {
    var numbers
    await axios
        .get(
            'https://9x9v95v7r4.execute-api.us-east-1.amazonaws.com/dev/getnumbers?functiontype=2'
        )
        .then((response) => {
            numbers = response.data
        })
    return numbers
}

const getRecentNumber = async () => {
    var recentNumber
    await axios
        .get(
            'https://9x9v95v7r4.execute-api.us-east-1.amazonaws.com/dev/getnumbers?functiontype=1'
        )
        .then((response) => {
            recentNumber = response.data
        })
    return recentNumber
}

const getWeatherVideos = async () => {
    var videos
    await axios
        .get(
            'https://9x9v95v7r4.execute-api.us-east-1.amazonaws.com/dev/getvideos?functiontype=4'
        )
        .then((response) => {
            videos = response.data
        })
    return [videos]
}

const getRecentWeatherVideo = async () => {
    var video
    await axios
        .get(
            'https://9x9v95v7r4.execute-api.us-east-1.amazonaws.com/dev/getvideos?functiontype=3'
        )
        .then((response) => {
            video = response.data
        })
    return video
}

const insertNumber = async (num, datestring) => {
    var res
    await axios
        .post(
            'https://9x9v95v7r4.execute-api.us-east-1.amazonaws.com/dev/insertnumbersandvideos?functiontype=1',
            {
                queryStringParameters: {
                    functiontype: 1,
                },
                number: {
                    number: num,
                    date: datestring,
                },
            }
        )
        .then((response) => {
            res = response.data
        })
    return res
}

const insertNumberVideo = async (typestring, ytid, datestring) => {
    var res
    await axios
        .post(
            'https://9x9v95v7r4.execute-api.us-east-1.amazonaws.com/dev/insertnumbersandvideos?functiontype=2',
            {
                video: {
                    type: typestring,
                    yt_id: ytid,
                    date: datestring,
                },
            }
        )
        .then((response) => {
            res = response.data
        })
    return res
}

const insertWeatherVideo = async (typestring, ytid, datestring) => {
    var res
    await axios
        .post(
            'https://9x9v95v7r4.execute-api.us-east-1.amazonaws.com/dev/insertnumbersandvideos?functiontype=3',
            {
                video: {
                    type: typestring,
                    yt_id: ytid,
                    date: datestring,
                },
            }
        )
        .then((response) => {
            res = response.data
        })
    return res
}

export {
    getNumbers,
    getRecentNumber,
    getRecentNumbersVideo,
    getNumbersVideos,
    getRecentWeatherVideo,
    getWeatherVideos,
    getYoutubeLinks,
    insertNumber,
    insertNumberVideo,
    insertWeatherVideo,
}
