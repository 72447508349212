import React, { useState } from 'react'
// import { getYoutubeLinks } from '../../api/davidnumbersapi'
import { Button, ButtonGroup, Grid } from '@material-ui/core'
import WeatherVideos from './components/videos/WeatherVideos'
const Weather = ({ data }) => {
    const [weatherView, setWeatherView] = useState('home')
    //test function to update excel sheet with youtube links
    // useEffect(() => {
    //     async function test() {
    //         const response = await getYoutubeLinks()
    //         console.log(response)
    //     }
    //     test()
    // })
    return (
        <div>
            <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                style={{ marginBottom: '10px' }}
            >
                <Button onClick={() => setWeatherView('home')}>Home</Button>
                <Button onClick={() => setWeatherView('videos')}>
                    Historical Videos
                </Button>
            </ButtonGroup>
            {weatherView === 'home' ? (
                <div>
                    <div>
                        <h1>What weather did David Lynch report today?</h1>
                    </div>
                    <Grid style={{ textAlign: '-webkit-center' }}>
                        <div style={{ width: '52vw', height: '52vh' }}>
                            <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${data.recentWeatherVideoID}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            />
                        </div>
                    </Grid>
                </div>
            ) : (
                <div>
                    <WeatherVideos videoids={data.weatherVideos} />
                </div>
            )}
        </div>
    )
}

export default Weather
