import axios from 'axios'

// const KEY = "AIzaSyBf-mBYkmKoyfGEBInvrtMxeZuMQBQGiho";
const KEY = 'AIzaSyBTpFEgPGMUtem9t0OKZRNk0DT0mPjqvho'

export default axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3',
    params: {
        part: 'snippet',
        type: 'video',
        maxResults: 31,
        key: KEY,
        order: 'date',
        fields: 'items(id,snippet/publishedAt,snippet/title)',
        channelId: 'UCDLD_zxiuyh1IMasq9nbjrA',
        publishedBefore: new Date('2021-07-31').toISOString(),
    },
})
