import React, { useState } from 'react'
import { Container, Box, Button, TextField } from '@material-ui/core'
import {
    insertNumber,
    insertNumberVideo,
    insertWeatherVideo,
} from '../../api/davidnumbersapi'

const InsertTool = () => {
    const [number, setNumber] = useState('')
    const [date, setDate] = useState('')
    const [numberVideo, setNumberVideo] = useState('')
    const [numVideoDate, setNumVideoDate] = useState('')
    const [weatherVideo, setWeatherVideo] = useState('')
    const [weatherVideoDate, setWeatherVideoDate] = useState('')

    const saveNumber = async (val) => {
        const res = await insertNumber(number, date)
        console.log(res)
    }

    const saveNumberVideo = async (val) => {
        const res = await insertNumberVideo('number', numberVideo, numVideoDate)
        console.log(res)
    }

    const saveWeatherVideo = async (val) => {
        const res = await insertWeatherVideo(
            'weather',
            weatherVideo,
            weatherVideoDate
        )
        console.log(res)
    }

    return (
        <Container maxWidth="xl" style={{ backgroundColor: '#ffffff' }}>
            <Box
                sx={{
                    height: '100vh',
                    textAlign: 'center',
                }}
            >
                <h1>Insert Tool</h1>
                <form>
                    <TextField
                        onChange={(e) => setNumber(e.target.value)}
                        label="Number"
                    />
                    <TextField
                        onChange={(e) => setDate(e.target.value)}
                        label="Date"
                    />
                    <Button variant="outlined" onClick={(e) => saveNumber(e)}>
                        Save Number
                    </Button>
                </form>
                <form>
                    <TextField
                        label="YT_ID"
                        onChange={(e) => setNumberVideo(e.target.value)}
                    />
                    <TextField
                        label="Date"
                        onChange={(e) => setNumVideoDate(e.target.value)}
                    />
                    <Button
                        variant="outlined"
                        onClick={(e) => saveNumberVideo(e)}
                    >
                        {' '}
                        Save Number Video
                    </Button>
                </form>
                <form>
                    <TextField
                        label="YT_ID"
                        onChange={(e) => setWeatherVideo(e.target.value)}
                    />
                    <TextField
                        label="Date"
                        onChange={(e) => setWeatherVideoDate(e.target.value)}
                    />
                    <Button
                        variant="outlined"
                        onClick={(e) => saveWeatherVideo(e)}
                    >
                        {' '}
                        Save Weather Video
                    </Button>
                </form>
            </Box>
        </Container>
    )
}

export default InsertTool
