import React from 'react'
import { Grid } from '@material-ui/core'

const NumbersVideos = ({ videoids }) => {
    return (
        <Grid container spacing={2}>
            {videoids &&
                videoids.map((video, index) => {
                    return (
                        <Grid item xs={4} key={index}>
                            <h3>{video.formatted_date}</h3>
                            <a
                                href={`https://www.youtube.com/watch?v=${video.yt_id}`}
                            >{`https://www.youtube.com/watch?v=${video.yt_id}`}</a>
                            {/* <iframe
                                width="500"
                                height="320"
                                src={`https://www.youtube.com/embed/${video.yt_id}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            /> */}
                        </Grid>
                    )
                })}
        </Grid>
    )
}

export default NumbersVideos
